import React, { useEffect, useRef, useState } from "react";
import "./address-map-picker.css";

export const AddressMapPicker = ({ handleAddressPicked, onCancel }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [userLocation, setUserLocation] = useState(null); // Start as null to delay map initialization

  const HERE_API_KEY = "NmepQNDX7J_MtXyCLyhwqNu_0nRMH97NvQ3o3Mqw5xc";
  const IP_GEOLOCATION_API_KEY = "0a0dfe0a8b1742b7b05b43dd6b6bf187";
  useEffect(() => {
    // Fetch user's location using IP (third-party API)
    fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${IP_GEOLOCATION_API_KEY}`)
      .then((res) => res.json())
      .then((data) => {
        setUserLocation({ lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) });
      })
      .catch((err) => {
        console.error("Failed to fetch user location", err);
        // Default to Berlin if user location can't be fetched
        setUserLocation({ lat: 52.52, lng: 13.405 });
      });
  }, []);

  useEffect(() => {
    if (userLocation && !mapInstanceRef.current && mapRef.current) {
      const H = window.H;
      const platform = new H.service.Platform({
        apikey: HERE_API_KEY,
      });

      const defaultLayers = platform.createDefaultLayers();

      // Initialize the map
      const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        center: userLocation,
        zoom: 14,
        pixelRatio: window.devicePixelRatio || 1,
      });

      // Enable map interactivity (dragging and zooming)
      const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      H.ui.UI.createDefault(map, defaultLayers);

      // Add a marker at the center of the map
      const marker = new H.map.Marker(map.getCenter(), { volatility: true });
      map.addObject(marker);

      const updateMarkerPosition = (coord) => {
        marker.setGeometry(coord);
        reverseGeocode(coord);
      };

      // Event listener for dragging
      map.addEventListener("dragend", () => {
        updateMarkerPosition(map.getCenter());
      });

      // Event listener for tapping on the map
      map.addEventListener("tap", (evt) => {
        const coord = map.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY
        );
        updateMarkerPosition(coord);
      });

      mapInstanceRef.current = map;
    }
  }, [userLocation]);

  const reverseGeocode = async (coords) => {
    const { lat, lng } = coords;
    const reverseGeocodingUrl = `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${lat},${lng}&lang=en-US&apikey=${HERE_API_KEY}`;

    try {
      const response = await fetch(reverseGeocodingUrl);
      const data = await response.json();
      if (data.items && data.items.length > 0) {
        const location = data.items[0].address;
        const address = {
          street: location.street || "",
          houseNumber: location.houseNumber || "",
          postalCode: location.postalCode || "",
          city: location.city || "",
          state: location.state || "",
          stateCode: location.stateCode,
          countryName: location.countryName || "",
          countryCode: location.countryCode,
          longitude: lng,
          latitude: lat,
          label: location.label,
          county: location.county,
          district: location.district,
        };
        setSelectedAddress(address);
      }
    } catch (error) {
      console.error("Failed to fetch reverse geocode data", error);
    }
  };

  const confirmAddress = () => {
    if (selectedAddress) {
      handleAddressPicked(selectedAddress);
      cleanupMap();
    }
  };

  const cleanupMap = () => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.dispose();
      mapInstanceRef.current = null;
    }
    onCancel();
  };

  return (
    <div className="map-picker-modal-overlay">
      <div className="map-picker-modal">
        <span className="close-map-picker-icon" onClick={cleanupMap}>
          &times;
        </span>
        <h2 className="map-picker-modal-title">Choose Your Address</h2>
        <div className="address-map-picker">
          {userLocation ? (
            <>
              <div
                ref={mapRef}
                style={{ width: "100%", height: "400px", border: "1px solid #ccc" }}
              />
              {selectedAddress && (
                <div className="selected-address-container">
                  <p className="selected-address">
                    <span className="address-line">
                      <i className="icon-flag" /> {selectedAddress.street}, {selectedAddress.number}
                    </span>
                    <span className="address-line">
                      {selectedAddress.postalCode} {selectedAddress.city}, {selectedAddress.state}
                    </span>
                    <span className="address-line">{selectedAddress.country}</span>
                  </p>
                </div>
              )}
              <div className="address-map-picker-buttons">
                <button
                  className="btn btn-secondary"
                  onClick={confirmAddress}
                  disabled={!selectedAddress}
                  style={{
                    marginRight: "10px",
                    opacity: selectedAddress ? "1" : "0.5",
                    cursor: selectedAddress ? "pointer" : "not-allowed",
                  }}
                >
                  Confirm Address
                </button>
              </div>
            </>
          ) : (
            <p>Loading map...</p> // Show loading message while fetching location
          )}
        </div>
      </div>
    </div>
  );
};
