import React, { useState } from 'react';
import { FaHome, FaTrash } from 'react-icons/fa'; // Import FaTrash for remove icon
import { AddAddressModal } from '../AddAddress/add-address-modal';
import { useAddressModal } from './useAddressModal.js';
import './address-modal.css';

export const AddressModal = ({ 
    userAddresses = [], 
    handleClose, 
    handleUserClick, 
    handleAddNewAddress,
    handleAddressRemoved,
}) => {
    const [showAddAddressModal, setShowAddAddressModal] = useState(userAddresses.length > 0 ? false : true);
    const { postNewAddress, setAddressAsMain, removeAddress } = useAddressModal();

    const handleAddressSelect = async (address) => {
        await setAddressAsMain(address);
        handleUserClick(address);
        handleClose();
    };

    const handleAddNewAddressBtnClicked = () => {
        setShowAddAddressModal(!showAddAddressModal);
    };

    const handleAddAddressModalClosed = () => {
        handleClose();
    };

    const handleAddAddressSave = async (newAddress) => {
        var result = await postNewAddress(newAddress);
        handleAddNewAddress({...newAddress, id: result.id });
    };

    const handleRemoveAddress = async (address) => {
        await removeAddress(address);
        handleAddressRemoved(address);
    };

    return (
        <>
            {showAddAddressModal && (
                <AddAddressModal
                    handleClose={handleAddAddressModalClosed}
                    handleSave={handleAddAddressSave}
                />
            )}
            {!showAddAddressModal && (
                <div className="address-modal-overlay">
                    <div className="address-modal">
                        <span className="close-icon" onClick={handleClose}>&times;</span>
                        <h2 className="modal-title">Choose an address</h2>
                        <ul className="address-list">
                            {userAddresses.map((address, index) => (
                                <div className="address-container">
                                    <li
                                        key={index}
                                        className="address-item"
                                    >
                                        <div 
                                            className="address-content"
                                            onClick={() => handleAddressSelect(address)}
                                        >
                                            <FaHome className="house-icon" />
                                            <span className="address-text">{address.label}</span>
                                        </div>
                                    </li>
                                    <FaTrash
                                        className="remove-icon"
                                        onClick={() => handleRemoveAddress(address)}
                                        title="Remove address"
                                    />
                                </div>  
                            ))}
                        </ul>
                        <div className="button-container">
                            <button
                                className="btn btn-secondary"
                                onClick={handleAddNewAddressBtnClicked}
                            >
                                Add new address
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
