import React, { useState } from "react";
import "./add-address-modal.css";
import { AddressAutocomplete } from "../../Input/AddressAutocomplete/address-autocomplete";
import { AddressMapPicker } from "../../Maps/AddressMapPicker/address-map-picker";

export const AddAddressModal = ({ handleClose, handleSave }) => {
  const [newAddress, setNewAddress] = useState("");
  const [showMapPicker, setShowMapPicker] = useState(false);

  const handleSaveAddress = async () => {
    if (newAddress) {
      await handleSave(newAddress.address);
      handleClose();
    }
  };

  const closeModal = () => {
    handleClose();
  }

  const handleSelectedAddress = (address) => {
    setNewAddress(address);
  };

  const handleOpenMapPicker = () => {
    setShowMapPicker(true);
  };

  const handleCloseMapPicker = () => {
    setShowMapPicker(false);
  };

  const handleMapSelection = (selectedAddress) => {
    handleSave(selectedAddress);
    handleCloseMapPicker();
    closeModal();
  };

  return (
    <div className="add-address-modal-overlay">
      {!showMapPicker && (
        <div className="add-address-modal bg-white shadow rounded p-4">
          <span className="close-add-address-modal-icon" onClick={closeModal}>
            &times;
          </span>
          <h2 className="add-address-modal-title text-center fw-bold mb-4">
            Add a service address
          </h2>
          <div className="add-address-content-container">
            <div>
              <img className="add-address-image" src="./map-icon.jpg"/>
            </div>
            <div>
              <AddressAutocomplete handleSelectedAddress={handleSelectedAddress} />
              <p
                className="text-center mt-2 text-primary cursor-pointer"
                onClick={handleOpenMapPicker}
              >
                Or set your location in the map
              </p>
              <div className="address-map-picker-buttons">
                <button
                  className="btn btn-secondary"
                  onClick={handleSaveAddress}
                  disabled={!newAddress}
                  style={{
                    marginTop: "10px",
                    marginRight: "10px",
                    opacity: !newAddress ? "0.1" : handleSaveAddress ? "1" : "0.5",
                    cursor: !newAddress? "not-allowed" : "pointer",
                  }}
                >
                  Confirm Address
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showMapPicker && (
        <AddressMapPicker
          handleAddressPicked={handleMapSelection}
          onCancel={handleCloseMapPicker}
        />
      )}
    </div>
  );
};
