import React, { useState } from 'react';
import './navigation.css';
import { FaHome, FaChevronDown } from 'react-icons/fa';
import { AddressModal } from '../Modal/Address/address-modal';
import { SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';

export const Navigation = ({ user, showAddresses = true, showAuthButtons = true }) => {
    const [showAddressModal, setShowAddressModal] = useState(false);

    const toggleModal = () => {
        setShowAddressModal(!showAddressModal);
    };

    const setUserAddress = (address) => {
        user.mainAddress = address;
    };

    const addNewAddress = (address) => {
        user.mainAddress = address;
        user.addresses = [...user.addresses, address];
    }

    const removeAddress = (address) => {
        user.addresses = user.addresses.filter((x) => x !== address);
    
        if (user.mainAddress === address) {
            user.mainAddress = user.addresses.length > 0 ? user.addresses[0] : undefined;
        }
    };
    

    return (
        <nav className="navbar navbar-dark bg-dark">
            <a className="navbar-brand gradient-text" href="/">
                <h1 className="main-text">home services</h1>
            </a>

            {showAddresses && (
                <div className="navbar-middle">
                    {user.mainAddress ? (
                        <div className="address-section" onClick={toggleModal}>
                            <FaHome className="house-icon" />
                            <span className="pre-address-text">Receive services in </span>
                            <span className="address-text">{user.mainAddress.label}</span>
                            <FaChevronDown className="arrow-icon" />
                        </div>
                    ) : (
                        <div className="address-section" onClick={toggleModal}>
                            <span className="address-text">Introduce an address</span>
                            <FaChevronDown className="arrow-icon" />
                        </div>
                    )}
                </div>
            )}

            <div className="navbar-right">
                {showAuthButtons && (
                    <>
                        <SignedIn>
                            <UserButton />
                        </SignedIn>
                        <SignedOut>
                            <div className="auth-buttons">
                                <a href="/signin" className="btn btn-outline-light me-2">
                                    Login
                                </a>
                                <a href="/signup" className="btn btn-light">
                                    Register
                                </a>
                            </div>
                        </SignedOut>
                    </>
                )}
            </div>

            {showAddressModal && (
                <AddressModal
                    userAddresses={user?.addresses}
                    handleClose={toggleModal}
                    handleUserClick={setUserAddress}
                    handleAddNewAddress={addNewAddress}
                    handleAddressRemoved={removeAddress}
                />
            )}
        </nav>
    );
};
