import { useContext } from 'react';
import axios from 'axios';
import { AppContext } from '../../contexts/appcontext';
import { useInterceptor } from './useInterceptor';

const baseUrl = process.env.REACT_APP_API_URL;

export const useApi = () => {
    const { setLoading, setError } = useContext(AppContext);
    const { authorizationInterceptor } = useInterceptor();

    const get = async (url, params) => {
        setLoading(true);
        const axiosInstance = axios.create({ baseURL: baseUrl } );
        authorizationInterceptor(axiosInstance);

        try {
            const response = await axiosInstance.get(`${baseUrl}/${url}`);
            setLoading(false);
            return response;
    
        } catch (error) {
            setLoading(false);
            return undefined;
        }
    }

    const post = async (url, data) => {
        setLoading(true);
        const axiosInstance = axios.create({ baseUrl: baseUrl });
        authorizationInterceptor(axiosInstance);

        try {
            const response = await axiosInstance.post(`${baseUrl}/${url}`, data);
            setLoading(false);
            return response;
    
        } catch (error) {
            setLoading(false);
            return undefined;
        }
    }

    const put = async (url, data) => {
        setLoading(true);
        const axiosInstance = axios.create({ baseUrl: baseUrl });
        authorizationInterceptor(axiosInstance);

        try {
            const response = await axiosInstance.put(`${baseUrl}/${url}`, data);
            setLoading(false);
            return response;
    
        } catch (error) {
            setLoading(false);
            return undefined;
        }
    }

    const del = async (url) => {
        setLoading(true);
        const axiosInstance = axios.create({ baseUrl: baseUrl });
        authorizationInterceptor(axiosInstance);

        try {
            const response = await axiosInstance.delete(`${baseUrl}/${url}`);
            setLoading(false);

            return response;
    
        } catch (error) {
            setLoading(false);
            return undefined;
        }
    }

    return(
        {
            get,
            post,
            put,
            del,
        }
    );
}
