import { useApi } from "../../../services/api/useApi";
import { url } from "../../../services/api/url";

export const useAddressModal = () => {
    const { post, put, del } = useApi();

    const postNewAddress = async (address) => {
        var result = await post(url.addresses, address);
        return result.data;
    }

    const setAddressAsMain = async (address) => {
        const payload = {
            id: address.id,
            isMain: true
        };

        await put(url.addresses, payload);
    }

    const removeAddress = async (address) => {
        await del(`${url.addresses}/${address.id}`);
    }

    return {
        postNewAddress,
        setAddressAsMain,
        removeAddress,
    };
}