import React, { useState, useEffect, useRef } from 'react';

const HERE_API_KEY = "NmepQNDX7J_MtXyCLyhwqNu_0nRMH97NvQ3o3Mqw5xc";

export const AddressAutocomplete = ({ handleSelectedAddress }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const debounceTimeout = useRef(null);
    const containerRef = useRef(null);

    const fetchSuggestions = async (input) => {
        if (input.length > 3) {
            const endpoint = `https://autocomplete.search.hereapi.com/v1/autocomplete?q=${encodeURIComponent(
                input
            )}&apiKey=${HERE_API_KEY}&limit=5&resultType=discover`;

            try {
                const response = await fetch(endpoint);
                const data = await response.json();

                setSuggestions(
                    data.items.map((item) => ({
                        display_name: item.title,
                        address: item.address,
                        id: item.id,
                    }))
                );
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };

    const fetchLocationDetails = async (id) => {
        const endpoint = `https://lookup.search.hereapi.com/v1/lookup?id=${encodeURIComponent(id)}&apiKey=${HERE_API_KEY}`;

        try {
            const response = await fetch(endpoint);
            const data = await response.json();
            return {
                latitude: data.position.lat,
                longitude: data.position.lng,
                address: data.address,
            };
        } catch (error) {
            console.error('Error fetching location details:', error);
            return null;
        }
    };

    const handleInputChange = (e) => {
        const input = e.target.value;
        setQuery(input);

        if (input.trim() === '') {
            setSuggestions([]);
            if (handleSelectedAddress) {
                handleSelectedAddress(null);
            }
            return;
        }

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            fetchSuggestions(input);
        }, 500); 
    };

    const handleSuggestionClick = async (suggestion) => {
        setQuery(suggestion.display_name);
        setSuggestions([]);

        const details = await fetchLocationDetails(suggestion.id);

        if (details) {
            handleSelectedAddress({
                address: {
                    ...details.address,
                    latitude: details.latitude,
                    longitude: details.longitude,
                }
            });
        }
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSuggestions([]); 
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Clean up the debounce timeout on component unmount
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    return (
        <div
            ref={containerRef}
            style={{ position: 'relative', maxWidth: '400px', margin: 'auto' }}
        >
            <input
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Enter an address"
                style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                }}
            />
            {suggestions.length > 0 && (
                <ul
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        zIndex: 1000,
                        listStyle: 'none',
                        margin: 0,
                        padding: '10px 0',
                    }}
                >
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                            style={{
                                padding: '10px',
                                cursor: 'pointer',
                                borderBottom: '1px solid #f0f0f0',
                            }}
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
